// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-governance-compliance-js": () => import("./../../../src/pages/governance-compliance.js" /* webpackChunkName: "component---src-pages-governance-compliance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-pagina-interna-js": () => import("./../../../src/templates/pagina-interna.js" /* webpackChunkName: "component---src-templates-pagina-interna-js" */),
  "component---src-templates-pagina-snodo-js": () => import("./../../../src/templates/pagina-snodo.js" /* webpackChunkName: "component---src-templates-pagina-snodo-js" */)
}

